import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

function ConfirmationScreen({ onConfirm }) {
  const [alertMessage, setAlertMessage] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const navigate = useNavigate();
  const { phoneNumber } = useParams();
  const handleDelete = async () => {
    await fetch(
      `${process.env.REACT_APP_SERVER_URL}/activity/deleteAccountWithTimeSpan`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        },
        body: JSON.stringify({ phoneNumber: "+" + phoneNumber }),
      }
    )
      .then(async (res) => {
        if (res.status === 200) {
          let responseJson = await res.json();
          setIsDeleted(true);
          setAlertMessage({
            type: "success",
            message: responseJson.message,
          });
          setTimeout(() => {
            setAlertMessage(null);
            navigate("/");
          }, 5000);
          //navigate("/");
        } else if (res.status === 201) {
          let responseJson = await res.json();
          setAlertMessage({
            type: "failed",
            message: responseJson.message,
          });
        } else {
          let responseJson = await res.json();
          setAlertMessage({
            type: "error",
            message: "Something went wrong",
          });
        }
      })
      .catch((error) => {
        setAlertMessage({
          type: "error",
          message: "Something went wrong",
        });
      });
  };

  const handleCancel = () => {
    navigate("/");
  };
  const currentDate = new Date();
  const deletionDate = new Date(
    currentDate.getTime() + 30 * 24 * 60 * 60 * 1000
  );
  useEffect(() => {
    if (alertMessage && alertMessage.type === "success") {
      const timeout = setTimeout(() => {
        setAlertMessage(null);
        navigate("/");
      }, 5000); // Display the message for 5 seconds (5000 milliseconds)

      return () => clearTimeout(timeout);
    }
  }, [alertMessage, navigate]);
  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-6 bg-gray-100">
      {alertMessage && (
        <div
          className={`${
            alertMessage.type === "success"
              ? "bg-green-200 text-green-800"
              : alertMessage.type === "failed"
                ? "bg-yellow-200 text-yellow-800"
                : "bg-red-200 text-red-800"
          } mb-4 p-4 rounded`}
        >
          {alertMessage.message}
        </div>
      )}
      <h2 className="text-4xl font-bold mb-8 text-center">
        Account Scheduled for Deletion
      </h2>
      <div className="max-w-lg bg-white rounded-lg shadow-lg p-6">
        <p className="text-lg text-gray-700 mb-8">
          Are you sure you want to delete your account? Deleting your account
          will permanently remove all your personal information, data, and
          associated content from our system. This action cannot be undone.
          Please consider the following before proceeding:
        </p>
        <ul className="list-disc pl-6 mb-8">
          <li className="text-gray-700">
            All your account data, including profile information, preferences,
            and activity history, will be permanently deleted.
          </li>
          <li className="text-gray-700">
            You will lose access to all the features, services, and benefits
            associated with your account.
          </li>
          <li className="text-gray-700">
            Any content you have shared or created, such as posts, comments, or
            files, will be permanently lost.
          </li>
          <li className="text-gray-700">
            You will no longer receive notifications, updates, or communications
            from us.
          </li>
        </ul>
        <p className="text-lg text-gray-700 mb-8">
          Please note that your account will be marked for deletion and remain
          active for the next 30 days. During this period, you have the
          opportunity to reconsider and reactivate your account by simply
          logging in to our app. If you change your mind, you can recover your
          account until {deletionDate.toDateString()} by logging in and
          following the reactivation process within the app.
        </p>
        <p className="text-lg text-gray-700 mb-8">
          If you are certain about deleting your account, please click the
          "Delete Account" button below. Otherwise, you can choose to cancel
          this action and continue using our services.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          We value your feedback and would appreciate it if you could take a
          moment to let us know the reason for your account deletion. Your input
          will help us improve our services for future users.
        </p>
        <p className="text-lg text-gray-700">
          Thank you for being a part of our community. If you have any questions
          or need assistance, please contact our support team, and we will be
          happy to assist you.
        </p>
      </div>
      {alertMessage && (
        <div
          className={`${
            alertMessage.type === "success"
              ? "bg-green-200 text-green-800"
              : alertMessage.type === "failed"
                ? "bg-yellow-200 text-yellow-800"
                : "bg-red-200 text-red-800"
          } mb-4 p-4 rounded`}
        >
          {alertMessage.message}
        </div>
      )}
      {!isDeleted && (
        <div className="flex space-x-4 mt-8">
          <button
            onClick={handleDelete}
            className="bg-red-500 text-white rounded-lg py-3 px-6 font-bold hover:bg-red-600"
          >
            Delete Account
          </button>
          <button
            onClick={handleCancel}
            className="bg-gray-300 text-gray-800 rounded-lg py-3 px-6 font-bold hover:bg-gray-400"
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
}

export default ConfirmationScreen;
