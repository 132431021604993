import React from "react";
import { FaLock } from "react-icons/fa";

function PrivacyPolicy() {
  const iconStyle = {
    width: "16px",
    height: "16px",
  };
  const policySections = [
    "As a new dating app, Gojo Dating App places the highest priority on the safety, security, and well-being of our users. We are committed to fostering a respectful and safe environment for individuals looking to connect with others. Our goal is to build a positive user experience, and we are dedicated to learning, improving, and implementing safety measures as we grow and expand.",
    "While we may be a new app, we are proactive in implementing safety measures to protect our users. We utilize a combination of automated and manual moderation tools, systems, and processes to prevent and identify inappropriate behavior. These measures include scans of profiles for red-flag language and images, manual reviews of suspicious accounts and activity, and encouraging users to report any concerns they encounter.",
    "Gojo Dating App maintains a zero-tolerance policy on harassment, regardless of our size or global reach. We strongly encourage our community to report any instances of misconduct, harassment, or violations of our Community Guidelines or Terms of Use. We take reports seriously, and our dedicated team will take appropriate actions to address any concerns promptly.",
    "In the event of reported misconduct or abusive behavior, our team will investigate the matter thoroughly and take appropriate action, which may include content removal, user banning, or cooperation with law enforcement resources when necessary. The safety and well-being of our users are paramount, and we are committed to supporting victims of abuse.",
    "Educating our users about safety is essential to us. We provide safety tips and guidelines online and within the app to help users make informed decisions while using Gojo Dating App. By promoting user education, we aim to create a community that prioritizes safety and mutual respect.",
    "We value user privacy and implement measures to safeguard user data. Our policies and technical systems are designed to protect user information, including encryption for user photos and messages. We do not share user data with third parties, and users have the right to request a copy of their data. Our Privacy Policy is easily accessible to all users within the app. Users can request to delete their data and we delete their essential personal informations in a month.",
    "At Gojo Dating App, we are committed to being an inclusive platform that welcomes users from all backgrounds, gender identities, and sexual orientations. We strictly enforce guidelines that promote respectful behavior and prohibit harassment, underage usage, solicitation, and profiles promoting illegal activities.",
    "As a new app, we understand that continuous improvement is vital for providing a safe and enjoyable experience. We are open to feedback from our users and will actively work to refine and enhance our safety measures as we grow and expand our user base.",
  ];

  const sectionTitles = [
    "Our Commitment",
    "Safety Measures",
    "Zero-Tolerance Policy on Harassment",
    "Response to Reports of Misconduct",
    "User Education",
    "Privacy and Data Security",
    "Inclusion and Community Guidelines",
    "Continuous Improvement",
  ];

  return (
    <div className=" min-h-screen p-6 text-gray-700 bg-white">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold mb-6">Safety and Privacy Policy</h1>
        <div className="space-y-6">
          {policySections.map((section, i) => (
            <div key={i}>
              <div className="flex items-center">
                <FaLock
                  style={iconStyle}
                  className="mr-2 text-green-500"
                />
                <h2 className="font-bold text-gray-400">{sectionTitles[i]}</h2>
              </div>
              <p className="mt-2">{section}</p>
            </div>
          ))}
        </div>

        <div className="mt-8">
          <p>
            We may be new, but our dedication to user safety and privacy is
            unwavering. We aspire to create a positive and secure environment
            where individuals can connect and build meaningful relationships. As
            we progress, we will continue to invest in safety efforts to make
            Gojo Dating App a trusted platform for meeting new people.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
