import React from 'react';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className=" text-white text-center p-4">
      <p>&copy; {currentYear} FetaTech LLC. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
