import React from "react";
import { FaRegListAlt } from "react-icons/fa";

function SubscriptionCancellation() {
  const iconStyle = {
    width: "20px",
    height: "20px",
  };

  return (
    <div className="min-h-screen p-6 text-gray-700 bg-white">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold mb-6">How to Cancel Your Subscription</h1>

        <div className="mb-10">
          <h2 className="text-3xl font-semibold mb-4">For iOS Users</h2>
          <FaRegListAlt style={iconStyle} className="mb-2 text-green-500" />
          <ol className="list-decimal ml-8">
            <li>Open the <strong>Settings</strong> app on your iOS device.</li>
            <li>Tap your <strong>Apple ID</strong> at the top of the screen.</li>
            <li>Select <strong>Subscriptions</strong>.</li>
            <li>Find and tap the subscription for <strong>Gojo Dating App</strong>.</li>
            <li>Choose <strong>Cancel Subscription</strong>.</li>
          </ol>
        </div>

        <div>
          <h2 className="text-3xl font-semibold mb-4">For Android Users</h2>
          <FaRegListAlt style={iconStyle} className="mb-2 text-green-500" />
          <ol className="list-decimal ml-8">
            <li>Open the <strong>Google Play Store</strong> app.</li>
            <li>Tap the <strong>Menu</strong> icon (three horizontal lines).</li>
            <li>Select <strong>Subscriptions</strong>.</li>
            <li>Find and tap the subscription for <strong>Gojo Dating App</strong>.</li>
            <li>Tap <strong>Cancel Subscription</strong>.</li>
          </ol>
        </div>

        <p className="mt-8">
          Note: Uninstalling the Gojo Dating App does not automatically cancel your subscription. 
          Please follow these steps to cancel your subscription and stop future billing.
        </p>
      </div>
    </div>
  );
}

export default SubscriptionCancellation;
