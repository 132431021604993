import React from "react";
import { useNavigate } from "react-router-dom";

function HelpActions() {
  const navigate = useNavigate();

  const handleDeleteAccount = () => {
    navigate("/phone-number");
  };

  const handleCancelSubscription = () => {
    navigate("/subscription-policy");
  };

  const handleContactDevelopers = () => {
    navigate("/contact-us");
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-6">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6">
        <h1 className="text-2xl font-bold text-center mb-4">Help & Support</h1>
        <div className="space-y-4">
          <button
            onClick={handleDeleteAccount}
            className="w-full bg-red-500 text-white rounded-lg py-2 px-4 hover:bg-red-600"
          >
            Delete Account
          </button>
          <button
            onClick={handleCancelSubscription}
            className="w-full bg-blue-500 text-white rounded-lg py-2 px-4 hover:bg-blue-600"
          >
            Cancel Subscription
          </button>
          <button
            onClick={handleContactDevelopers}
            className="w-full bg-green-500 text-white rounded-lg py-2 px-4 hover:bg-green-600"
          >
            Contact Developers
          </button>
        </div>
      </div>
    </div>
  );
}

export default HelpActions;
