import "./index.css";
import "animate.css";
import Home from "./Pages/Home.js";
import Header from "./components/Header.js";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Guidelines from "./Pages/Guidelines.js";
import Privacy from "./Pages/Privacy.js";
import About from "./Pages/About.js";
import Help from "./Pages/Help.js";
import Footer from "./components/Footer.js";
import HelpActions from "./components/HelpActions.js";
import PhoneNumberInputScreen from "./components/PhoneNumberInputScreen.js";
import VerifyOTPInputScreen from "./components/VerifyOTPInputScreen.js";
import ConfirmationScreen from "./components/ConfirmationScreen.js";
import SubscriptionCancellation from "./Pages/SubscriptionCancellation.js";
import ContactUs from "./Pages/ContactUS.js";
import Payment from "./Pages/Payment.js";
import NotFoundPage from "./Pages/Notfound.js";
import PaymentConfirmation from "./Pages/PaymentConfirmation.js";
import GuidelinesIOS from "./Pages/GuidelinesIOS.js";
import PrivacyIOS from "./Pages/PrivacyIOS.js";

function App() {
  return (
    <Router>
      <div id="modal-root"></div>
      <div className="App bg-customColor">
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/guidelines" element={<Guidelines />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/guidelines/IOS" element={<GuidelinesIOS />} />
          <Route path="/privacy/IOS" element={<PrivacyIOS />} />
          <Route path="/about" element={<About />} />
          <Route path="/help" element={<Help />} />
          <Route path="/help-actions" element={<HelpActions />} />
          <Route path="/phone-number" element={<PhoneNumberInputScreen />} />
          <Route
            path="/subscription-policy"
            element={<SubscriptionCancellation />}
          />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/payment/:userId" element={<Payment />} />
          <Route
            path="/payment-confirmation"
            element={<PaymentConfirmation />}
          />
          <Route
            path="/verify-OTP/:phoneNumber"
            element={<VerifyOTPInputScreen />}
          />
          <Route
            path="/confirm-deletion/:phoneNumber"
            element={<ConfirmationScreen />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
