const packages = [
  {
    title: "Weekly Silver",
    planId: "silver_weekly",
    planType: "silver",
    amount: 100,
    duration: "1 Week",
    duration_id: "weekly",
    benefits: [
      "Match with 4 verified users per day.",
      "Match with 14 other users per day.",
      "Chat without matching with 4 users per day.",
    ],
  },
  {
    title: "Weekly Gold",
    planId: "gold_weekly",
    planType: "gold",
    amount: 200,
    duration: "1 Week",
    duration_id: "weekly",
    benefits: [
      "Match with 8 verified users per day.",
      "Match with 18 other users per day.",
      "Chat without matching with 8 users per day.",
      "set preferred location globally.",
      "can like back globally.",
      "can reset previously seen potential matches.",
    ],
  },
  {
    title: "Weekly Platinum",
    planId: "platinum_weekly",
    planType: "platinum",
    amount: 300,
    duration: "1 Week",
    duration_id: "weekly",
    benefits: [
      "Match with 25 verified users per day.",
      "Match with 25 other users per day.",
      "Chat without matching with 50 users per day.",
      "set preferred location globally.",
      "can like back globally.",
      "Incognito mode.",
      "can reset previously seen potential matches.",
    ],
  },
  {
    title: "Monthly Silver",
    planId: "silver_monthly",
    planType: "silver",
    amount: 300,
    duration: "1 Month",
    duration_id: "monthly",
    benefits: [
      "Match with 4 verified users per day.",
      "Match with 18 other users per day.",
      "Chat without matching with 4 users per day.",
    ],
  },
  {
    title: "Monthly Gold",
    planId: "gold_monthly",
    planType: "gold",
    amount: 600,
    duration_id: "monthly",
    duration: "1 Month",
    benefits: [
      "Match with 8 verified users per day.",
      "Match with 14 other users per day.",
      "Chat without matching with 8 users per day.",
      "set preferred location globally.",
      "can like back globally.",
      "can reset previously seen potential matches.",
    ],
  },
  {
    title: "Monthly Platinum",
    planId: "platinum_monthly",
    planType: "platinum",
    amount: 900,
    duration_id: "monthly",
    duration: "1 Month",
    benefits: [
      "Match with 25 verified users per day.",
      "Match with 25 other users per day.",
      "Chat without matching with 50 users per day.",
      "set preferred location globally.",
      "can like back globally.",
      "Incognito mode.",
      "can reset previously seen potential matches.",
    ],
  },
];

export { packages };
