import React from "react";
import { FaCheckCircle } from "react-icons/fa";

function Guidelines() {
  const iconStyle = {
    width: "16px",
    height: "16px",
  };

  const rules = [
    "Respect Boundaries: Everyone's comfort levels vary, so we prohibit nudity, sexual content, and explicit sexual desires on public profiles. In private conversations, such content is allowed only if all parties consent. Consent is essential.",
    "No Violent Content: We promote positivity and do not tolerate violent content, including gore, death, or images promoting harm to humans or animals. We also prohibit any advocacy or glorification of self-harm.",
    "Be Cautious with Personal Information: Avoid sharing personal details like phone numbers, emails, or social handles publicly on Gojo Matching App. Never ask others to share sensitive information, and be cautious when sending money to other users.",
    "Gojo Matching App is for personal connections, not for advertising, promotions, fundraising, or selling products or services. Sex work, escort services, and compensated relationships are also not allowed on our platform.",
    "Create genuine profiles and be true to yourself. Pretending to be someone else or using fake accounts is not allowed.",
    "Treat others with respect and kindness, understanding that users come from diverse backgrounds. Harassment, threats, bullying, doxing, sextortion, and other harmful behaviors are strictly prohibited.",
    "We stand against racism, bigotry, hatred, and violence based on factors like race, ethnicity, religion, gender, sexual orientation, disability, age, or national origin. Report users only for policy violations, not for personal preferences.",
    "Any acts or behavior causing harm, both on and offline, will not be tolerated on Gojo Matching App. If you experience harm, please reach out for support and, if needed, report the incident to us.",
    "Users must be 18 years or older to use Gojo Matching App. Do not post images of unaccompanied or unclothed minors.",
    "Illegal content or activities, such as drug-related activities, counterfeit goods, or human trafficking, are strictly prohibited.",
    "Each account must have a single owner, and multiple people cannot share the same account.",
    "Do not share images or private messages from others without their consent. Do not post copyrighted or trademarked work of others.",
    "Do not abuse Gojo Matching App or manipulate the system. Avoid spreading false or misleading information, spam, or harmful links. Don't use third-party apps to unlock features.",
    "Log in to your Gojo Matching App account periodically to keep it active. Inactive accounts for 6 months may be deleted.",
    "We encourage users to report any harmful behavior, violations, or discomfort they experience on Gojo Matching App. Your reports will be kept confidential and can help us maintain a safe environment for all.",
    "We take our Community Guidelines seriously and will take appropriate actions to address violations. Account termination may occur in severe cases or for repeated violations.",
    "At Gojo Matching, our policy emphasizes user convenience and flexibility. Subscribers can easily manage their subscriptions through integrated payment methods like Apple Pay and Google Pay, offering a streamlined process for both billing and cancellation. Our cancellation policy allows users to discontinue their subscription at any time directly through these payment platforms. In terms of refunds, Gojo is committed to customer satisfaction and offers refunds for accidental subscription payments, provided users reach out to us within a reasonable timeframe. We ensure that all policy changes, including those related to subscriptions, are communicated promptly to our users via in-app notifications and announcements on our platform. This approach ensures our users are always well-informed and can make decisions that best suit their needs.",
  ];

  const ruleTitles = [
    "Respect Boundaries",
    "No Violent Content",
    "Be Cautious with Personal Information",
    "No Business-Related Activities",
    "Be Authentic",
    "Communicate Respectfully",
    "No Place for Hate",
    "Avoid Harmful Behavior",
    "Adults Only",
    "Abide by the Law",
    "One Account Per Person",
    "Respect Privacy",
    "Be an Honest Member",
    "Stay Active",
    "Reporting",
    "Impact",
    "Subscripton Details",
  ];

  return (
    <div className=" min-h-screen p-6 text-gray-700 bg-white">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold mb-6">Community Guidelines</h1>
        <p className="mb-4">
          Gojo Matching App is a platform where meaningful connections can
          flourish. We believe that every swipe has the potential to lead to
          exciting possibilities. Our goal is to create a fun, safe, and
          inclusive space where individuals can be themselves while getting to
          know others. These Community Guidelines are here to set expectations
          for everyone's behavior, ensuring a positive experience for all users
          on our app. Failure to adhere to these guidelines may result in
          consequences, ranging from warnings to account suspension.
        </p>
        <div className="space-y-6">
          {rules.map((rule, i) => (
            <div key={i}>
              <div className="flex items-center">
                <FaCheckCircle
                  style={iconStyle}
                  className="mr-2 text-green-500"
                />
                <h2 className="font-bold text-gray-400">{ruleTitles[i]}</h2>
              </div>
              <p className="mt-2">{rule}</p>
            </div>
          ))}
        </div>

        <div className="mt-8">
          <p>
            We reserve the right to investigate and/or terminate accounts
            without a refund if users misuse the app, behave inappropriately, or
            violate our Community Guidelines or Terms of Use, even if the
            actions occur outside the app but involve others met through the
            service.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Guidelines;
