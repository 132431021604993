import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

function PhoneNumberInputScreen() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [alertMessage, setAlertMessage] = useState(null);
  const navigate = useNavigate();
  const handleSubmit = async () => {
    await fetch(`${process.env.REACT_APP_SERVER_URL}/user/sendOTP`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
      },
      body: JSON.stringify({ phoneNumber: "+" + phoneNumber }),
    })
      .then(async (res) => {
        if (res.status === 200) {
          let responseJson = await res.json();
          setAlertMessage({
            type: "success",
            message: responseJson.message,
          });
          navigate(`/verify-OTP/${phoneNumber}`);
        } else if (res.status === 201) {
          let responseJson = await res.json();
          setAlertMessage({
            type: "failed",
            message: responseJson.message,
          });
        } else {
          let responseJson = await res.json();
          setAlertMessage({
            type: "error",
            message: "Something went wrong",
          });
        }
      })
      .catch((error) => {
        setAlertMessage({
          type: "error",
          message: "Something went wrong",
        });
      });
  };

  useEffect(() => {
    if (alertMessage) {
      const timeout = setTimeout(() => {
        setAlertMessage(null);
      }, 3000); // Adjust the duration (in milliseconds) as needed

      return () => clearTimeout(timeout);
    }
  }, [alertMessage]);
  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-6 bg-darkPink">
      {alertMessage && (
        <div
          className={`${
            alertMessage.type === "success" ? "bg-green-500" : "bg-red-500"
          } text-white rounded-lg py-2 px-4 mb-4`}
        >
          {alertMessage.message}
        </div>
      )}
      <div className="flex mb-4">
        <div className="phone-input-container">
          <PhoneInput
            country={"et"} // Set the default country
            value={phoneNumber}
            onChange={setPhoneNumber}
          />
          <span className={`placeholder${phoneNumber ? " active" : ""}`}>
            Enter your phone number
          </span>
        </div>
      </div>
      <button
        onClick={handleSubmit}
        className="bg-blue-500 text-white rounded-lg py-2 px-4 hover:bg-blue-600"
      >
        Verify your phone
      </button>
    </div>
  );
}

export default PhoneNumberInputScreen;
