import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-white">
      <div className="text-center">
        <h1 className="text-9xl font-extrabold text-gray-800 drop-shadow-md">
          404
        </h1>
        <p className="text-2xl md:text-3xl font-bold text-gray-800 mb-6">
          Oops! We can't find that page.
        </p>
        <p className="mb-8 text-lg text-gray-600">
          But don't worry, you can find plenty of other things on our homepage.
        </p>
        <div className="space-y-6 mb-6">
          <Link
            to="/"
            className="inline-block bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-6 rounded-lg transition duration-300"
          >
            Back to Home
          </Link>
          <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4 mt-6">
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <a
                href="https://t.me/gojo_dating"
                className="text-blue-500 hover:text-blue-700 transition duration-300"
              >
                Telegram: @gojo_dating
              </a>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow">
              <a
                href="mailto:info@gojodating.com"
                className="text-blue-500 hover:text-blue-700 transition duration-300"
              >
                Email: info@gojodating.com
              </a>
            </div>
          </div>
        </div>
        <p className="text-sm text-gray-500">
          If you think this is a mistake, please let us know.
        </p>
      </div>
    </div>
  );
};

export default NotFoundPage;
