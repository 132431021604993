import {packages} from "../constants/constants"
import { useLocation } from 'react-router-dom';

function PaymentConfirmation() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const planId = queryParams.get('plan_id');
    const expirationDate = queryParams.get('expiration_date');
  
    const plan = packages.find(p => p.planId === planId);
  
    if (!plan) {
      return <div>Invalid plan ID.</div>;
    }
  
    return (
      <div className="p-6">
        <h1 className="text-2xl font-bold text-center mb-4 text-gray-400">Payment Confirmation</h1>
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4 bg-green-100 border-l-4 border-green-500 text-green-700 p-4">
            <p className="font-bold">Congratulations!</p>
            <p>You've successfully subscribed to our <strong>{plan.title}</strong> package.</p>
          </div>
  
          <p>Your subscription will renew on <strong>{expirationDate}</strong>. Remember to cancel it before this date if you do not wish to continue being charged.</p>
          
          <h3 className="text-lg font-semibold mt-4 mb-2">Plan Benefits:</h3>
          <ul className="list-disc list-inside">
            {plan.benefits.map((benefit, index) => (
              <li key={index}>{benefit}</li>
            ))}
          </ul>
  
          <p className="mt-4">Please reopen the app to start enjoying your package benefits.</p>
  
          {/* <div className="mt-6">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Upgrade Your Package
            </button>
          </div> */}
        </div>
      </div>
    );
  }
  
  export default PaymentConfirmation;